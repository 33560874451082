import { useState, useContext, useEffect } from "preact/hooks";
import { createContext, Component } from "preact";
import { SessionContext } from "../SessionContext.tsx";
import { ProgressiveRequest } from "../ProgressiveRequest.tsx";
import { getServer } from '../Server.tsx';
import IconChevronLeft from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/chevron-left.tsx";
import IconX from "https://deno.land/x/tabler_icons_tsx@0.0.3/tsx/x.tsx";

export function InvoicingContent({ back, args }){

	return (
		<div>
			<_InvoicingContent args={args} back={back} />
		</div>
	);
}

class _InvoicingContent extends Component {
	static contextType = SessionContext;
	
	state = {
		loading: true,
		items: null,
		importing: [],
	};

	componentDidMount() {
		let that = this;

		this.load();
	}

	isLoading = false;
	load(){
		let that = this;
		let session = this.context;

		if(this.isLoading){
			return;
		}

		this.isLoading = true;
		ProgressiveRequest({
			url: getServer()+'/api/front/invoicing',
			method: 'post',
			data: {
				action: 'content',
				session: session
			},
			receiving: function(data){

				console.log('receiving');
				console.log(data);

				that.setState({
					items: data.items
				});

			},
			received: function(){
				console.log('done');
				that.isLoading = false;
			}
		});
	}

	displayContent = function(item){
		this.setState({content: item});
	}

	dragOver = function(e) {
		this.cancelDefault(e);
		document.getElementById("dropbox").classList.add("hover");
	}
	
	dragLeave = function(e) {
		document.getElementById("dropbox").classList.remove("hover");
	}
	
	cancelDefault = function(e) {
		e.preventDefault();
		e.stopPropagation();
		return false;
	}

	drop = function(e) {
		this.cancelDefault(e);
		this.dragLeave(e);
		let files = e.dataTransfer ? e.dataTransfer.files : document.getElementById("fileElem").files;
		if (files[0].type == "text/csv" || files[0].type == "text/plain") {//maybe just check name extention instead?
			if (files[0].size > 10000000)//10MB
			alert("Taille trop grande (10MB maximum)");
			else{
				files[0].status = 0;
				this.setState(prevState => ({
					importing: [...prevState.importing, files[0]]
				  }));
				  this.uploadFile(files[0]);
			} 
		} else {
			alert("Mauvais format de fichier");
		}
	}

	uploadFile = function(file) {
		let that = this;
		let url = getServer()+'/api/front/invoicing/upload'

		var xhr = new XMLHttpRequest()
		var formData = new FormData()
		xhr.open('POST', url, true)

		xhr.upload.addEventListener("progress", function(e) {
			that.updateAttr(that.state.importing.indexOf(file), "p", (e.loaded * 100.0 / e.total) || 100)
		})

		xhr.addEventListener('readystatechange', function(e) {
			if (xhr.readyState == 4 && xhr.status == 200) {
				that.updateAttr(that.state.importing.indexOf(file), "status", 2)
			} else if (xhr.readyState == 4 && xhr.status != 200) {
				that.updateAttr(that.state.importing.indexOf(file), "status", 3)
			}
		})
		formData.append('file', file)
		xhr.send(formData)
	  }

	  updateAttr = function(i, attr, e) {
		let arr = this.state.importing;
		arr[i][attr] = e;
		this.setState({
			importing: arr
		});
	  }
	
	render() {
		let that = this;
		let statustr = [
			{name: "En attente", color: "danger"},
			{name: "En cours", color: "warning"},
			{name: "Terminé", color: "info"}
		];

		if(this.state.items && this.state.items.invoices.data){
			
			return (
				<div>
					<div class="d-flex align-items-center mt-2 mb-4">
						{that.props.back?
							<div class="p-1 rounded-pill text-white me-4 arrowb" onClick={that.props.back}>
								<IconChevronLeft class="w-6 h-6" />
							</div>:
							<a class="p-1 rounded-pill text-white me-4 arrowb" href="/">
								<IconChevronLeft class="w-6 h-6" />
							</a>
						}
						<h1 class="d-flex align-items-center h3 mb-0">
							<img class="w-6 h-6 d-inline-block me-1" src="icons/Transactions_Historique Paiements Facture.svg" />
							<span class="ms-2 titleb">Les factures</span>
						</h1>
					</div>
					<div class="mt-4 mb-2">
						<div class="card" style="background-color:rgb(235 236 240)">
							<div class="card-body">
								<div class="h3 titleb">La facturation</div>
								<div>L'ensemble des données de vente de vos outils est consolidé et disponible dans les différents formats d'export comptable.</div>
							</div>
							<img style="width: 20px;position: absolute;right: 15px;top: 15px;" src="icons/Parcours Client_Aide.svg" />
						</div>
					</div>
					<div class="my-3">
						<div class="card shadow">
							<div class="card-body">
								<div class="h3 titleb">Téléverser</div>
								<input type="file" id="fileElem" multiple accept="text/*" onChange={(e) => this.drop(e)} hidden />
    							<label for="fileElem" class="d-block">
								<div id="dropbox" class="dropbox" onDragEnter={(evt) => this.cancelDefault(evt)} onDragOver={(evt) => this.dragOver(evt)} onDragLeave={(evt) => this.dragLeave(evt)} onDrop={(evt) => this.drop(evt)}>
									<div class="p-4 text-center">
										<img class="w-6 h-6 d-inline-block me-1" src="icons/Transactions_Historique Paiements Facture.svg" />
										<br />
										<b>Glisser & déposer</b> ou <span class="b" style="color:rgb(113 216 255)">Choisir un fichier</span>
										<br />CVS ou TXT
									</div>
								</div></label>
								<div class="importing">
									{this.state.importing.map((e)=>{
										return <div>
										<div class="d-flex justify-content-between">
											<div class="d-flex">
												<img class="w-8 h-8 d-inline-block me-1" src="icons/Services_Facture Generique.svg" />
												<div class=""><span>{e.name}</span><br /><span>{Math.floor(e.size/1000)}KB - {e.status == 2 ? "terminé" : ( e.status == 3 ? "erreur" : ""+(e.p>50?"1":"2"))+" secondes restantes"}</span></div>
											</div>
											<div class="">
												<IconX onClick={() => {this.state.importing.splice(this.state.importing.indexOf(e), 1);this.setState({importing: this.state.importing})}} />
												<div>{e.p}%</div>
											</div>
										</div>
										<div class="slider"><div style={{height: "100%", background: "#019fe3", borderRadius: "inherit", width: e.p+'%'}}></div></div>
										</div>
									})}
								</div>
							</div>
						</div>
					</div>
					<div class="my-3">
						<div class="card shadow">
							<div class="card-body p-0 titleb">
								<div class="h3 titleb p-3">Les dernières factures</div>
								<div>{
									this.state.items.invoices.data.list.map((invoice)=>{console.log(statustr[parseInt(invoice.attributes.status)].color)
										return <div class="d-flex align-items-center justify-content-between my-2 border-top px-3">
											<div class="">
												<span>{(new Date(invoice.createdAt)).toLocaleDateString("fr")}</span>
											</div>
											<div class="">
												<span>{invoice.id_item}</span>
											</div>
											<div class="">
												<span class="ms-2">{invoice.attributes.status == 2 ? invoice.attributes.amount + '€' : ""}</span>
											</div>
											<span class={"badge rounded-pill bg-"+(statustr[parseInt(invoice.attributes.status)].color)+" text-dark me-2"}>{statustr[parseInt(invoice.attributes.status)].name}</span>
										</div>
									})
								}</div>
							</div>
						</div>
					</div>
				</div>
			);

		}

		return <div>
			<div class="spinner-border text-secondary" role="status">
				<span class="visually-hidden">Loading...</span>
			</div>
		</div>
	}
}
